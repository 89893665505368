html,
body {
    height: 100%;
    width: 100%;
}

.doc-title {
    left: -12px;
    padding: 6px 40px 10px 28px;
    background: linear-gradient(
        90deg,
        rgba(28, 128, 185, 1) 0%,
        rgba(45, 146, 173, 1) 35%,
        rgba(69, 160, 161, 1) 100%
    );
    color: #ffffff;
    display: inline-block;
    position: relative;
    border-radius: 7px 7px 7px 0;
    font-size: 1.7rem;
    font-weight: 500;
}

.doc-title::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-color: #b2b8e4;
    border-width: 9px 0 0 11px;
}

.doc-title h1,
h3,
h2,
h4 {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin: 1px 1px 1px 1px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.arrow {
    margin-right: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.blue {
    background: rgb(23, 77, 99);
    background: linear-gradient(
        90deg,
        rgba(23, 77, 99, 1) 0%,
        rgba(27, 93, 120, 1) 35%,
        rgba(32, 106, 137, 1) 100%
    );
}

.green {
    background: rgb(53, 165, 122);
    background: linear-gradient(
        90deg,
        rgba(53, 165, 122, 1) 0%,
        rgba(51, 170, 120, 1) 35%,
        rgba(50, 171, 119, 1) 100%
    );
}

.back-btn:hover {
    cursor: pointer;
    opacity: 85%;
}

.back-btn {
    text-decoration: none;
    border: none;
    color: white;
    margin-bottom: 35px;
    background: rgb(23, 77, 99);
    background: linear-gradient(
        90deg,
        rgba(23, 77, 99, 1) 0%,
        rgba(27, 93, 120, 1) 35%,
        rgba(32, 106, 137, 1) 100%
    );
    padding: 10px 25px;
    border-radius: 10px;
}





header.header {
  background: url('src/assets/img/banner/Banner_KitEscolar.png');
  background-size: cover;
  background-position: right bottom;
  height: 300px;
  background-color: linear-gradient(
      90deg,
      rgba(28, 128, 185, 1) 0%,
      rgba(45, 146, 173, 1) 35%,
      rgba(69, 160, 161, 1) 100%
  );
}
.imgs-container {
  display: flex;
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding-left: 100px;
  margin-left: 30px;
}

.line {
  width: 60%;
  border-top: 2px solid;
  box-sizing: border-box;
  color: #ffffff;
}
.kit-logo {
  height: auto;
  /* min-width: 250px; */
  width: 95%;
  max-width: 600px;
}

.city-logo {
  height: auto;
  width: 30%;
  min-width: 250px;
}
@media screen and (max-width: 800px) {
  header.header {
      width: 100%;
      background-color: linear-gradient(
          90deg,
          rgba(28, 128, 185, 1) 0%,
          rgba(45, 146, 173, 1) 35%,
          rgba(69, 160, 161, 1) 100%
      );
  }
  .imgs-container {
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      align-items: center;
      margin-left: 0;
  }

  .kit-logo {
      height: auto;
  }
  .city-logo {
      height: auto;
  }
}

.buttons {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

button {
  border: none;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 250px;
}